import { number } from "prop-types"

const padNumber = (number, length = 2) =>
  number.toString().padStart(length, '0')

const getTimeZoneOffset = (timeZoneOffsetInMinutes) => {
  const sign = timeZoneOffsetInMinutes > 0 ? '-' : '+'
  const hours = Math.floor(Math.abs(timeZoneOffsetInMinutes) / 60)
  const minutes = Math.abs(timeZoneOffsetInMinutes) % 60
  return sign + padNumber(hours) + '' + padNumber(minutes)
}

const getTimeZone = (date: Date) => {
  const timeZoneOffsetInMinutes = date.getTimezoneOffset()
  return getTimeZoneOffset(timeZoneOffsetInMinutes)
}

export const formatDateWithTimezone = (date) => {
  const day = padNumber(date.getDate(), 2)
  const month = padNumber(date.getMonth() + 1, 2) // Months are zero-based
  const year = date.getFullYear()
  const hours = padNumber(date.getHours(), 2)
  const minutes = padNumber(date.getMinutes(), 2)
  const seconds = padNumber(date.getSeconds(), 2)
  const milliseconds = padNumber(date.getMilliseconds(), 3)
  const timezoneOffset = -date.getTimezoneOffset()
  const sign = timezoneOffset >= 0 ? '+' : '-'
  const offsetHours = padNumber(Math.floor(Math.abs(timezoneOffset) / 60), 2)
  const offsetMinutes = padNumber(Math.abs(timezoneOffset) % 60, 2)

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}.${milliseconds} ${sign}${offsetHours}${offsetMinutes}`
}
export const getFormattedDate = (date1: Date) => {
  if (!date1) {
    return ''
  }
  const timeZoneOffsetInMinutes = date1.getTimezoneOffset()
  let currentDate = ''
  const date = new Date(date1)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hh = date.getHours()
  const mm = date.getMinutes()
  currentDate = `${day}-${month}-${year} ${hh}:${mm} ${getTimeZoneOffset(
    timeZoneOffsetInMinutes
  )}`
  return currentDate
}

export const getEncodedFormattedDate = (date1: Date) => {
  const timeZoneOffsetInMinutes = date1.getTimezoneOffset()
  let currentDate = ''
  const date = new Date(date1)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hh = date.getHours()
  const mm = date.getMinutes()
  currentDate = `${day}-${month}-${year} ${hh}:${mm} ${getTimeZoneOffset(
    timeZoneOffsetInMinutes
  )}`
  return encodeURIComponent(currentDate)
}

export function getTimeOnly(dateString) {
  const date = new Date(dateString)
  const time = date.toLocaleTimeString('en-IN', {
    hour: '2-digit',
    hour12: true, // Delete for 24-hour format
    minute: '2-digit',
  })
  return time
}

export function getDisplayDateTime(dateString) {
  const date = new Date(dateString)
  const time = date.toLocaleString('en-IN', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    hour12: true, // Delete for 24-hour format
    minute: '2-digit',
  })
  return time
}

export function getDisplayDate(dateString) {
  const date = new Date(dateString)
  const time = date.toLocaleString('en-IN', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  })
  return time
}

export function getDisplayTime(dateString) {
  const date = new Date(dateString)
  const time = date.toLocaleString('en-IN', {
    hour: '2-digit',
    hour12: true, // Delete for 24-hour format
    minute: '2-digit',
  })
  return time
}

export const isValidGST = (gstin: string) => {
  return gstinRegex.test(gstin)
}

export const noEmptyRegex = /^(?!\s*$).+/
export const gstinRegex = /^([0-9]{2}[A-Z0-9]{13})|URP$/
export const pincodeRegex = /^[1-9][0-9]{5}$/
export const vehicleNumberRegex = /^[A-Z]{2}[0-9A-Z]{0,13}$/

export const isValidPincode = (pincode: string) => {
  return pincodeRegex.test(pincode)
}

export const isValidVehicleNumber = (vehicleNumber: string) => {
  const indianVehicleNumberRegex =
    /^[A-Z]{2}\s?[0-9]{1,2}\s?[A-Z]{1,3}\s?[0-9]{1,4}$/
  const x = indianVehicleNumberRegex.test(vehicleNumber)
  return indianVehicleNumberRegex.test(vehicleNumber)
}

export const isValidTransporterDocumentNumber = (documentNumber: string) => {
  // Regular expression to match the pattern of Transporter Document Number
  const transporterDocNumberRegex = /^[A-Z0-9]{10}$/

  // Check if the entered value matches the regex pattern
  return transporterDocNumberRegex.test(documentNumber)
}

export const getFormattedDateTimeIST = (date1: Date) => {
  let currentDate = ''
  const date = new Date(date1)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hh = date.getHours()
  const mm = date.getMinutes()

  currentDate = `${day}-${month}-${year} ${hh}:${mm} ${getTimeZone(date1)}`
  return currentDate
}

export const replace12AmWithCurrentTime = (date1: Date) => {
  const date = new Date(date1)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hh = date.getHours()
  const mm = date.getMinutes()
  if (hh === 0 && mm === 0) {
    const currentDate = new Date()
    const day2digit = day < 10 ? `0${day}` : `${day}`
    const month2digit = month < 10 ? `0${month}` : `${month}`
    const h2digit =
      currentDate.getHours() < 10
        ? `0${currentDate.getHours()}`
        : `${currentDate.getHours()}`
    const m2digit =
      currentDate.getMinutes() < 10
        ? `0${currentDate.getMinutes()}`
        : `${currentDate.getMinutes()}`
    const dateWithCurrentTime = `${month2digit}-${day2digit}-${year} ${h2digit}:${m2digit}:00`
    return new Date(dateWithCurrentTime)
  }
  return date1
}

export const getFormattedStartDateString = (date1: Date, encoded = true) => {
  let currentDate = ''
  const date = new Date(date1)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const timeZone = getTimeZone(date1).replace('+', '')
  if (encoded) {
    currentDate = `${day}-${month}-${year}%2000%3A00%20%2B${timeZone}`
  } else {
    currentDate = `${day}-${month}-${year} 00:00 +${timeZone}`
  }
  return currentDate
}

export const getFormattedEndDateString = (date1: Date, encoded = true) => {
  let currentDate = ''
  const date = new Date(date1)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const timeZone = getTimeZone(date1).replace('+', '')
  if (encoded) {
    currentDate = `${day}-${month}-${year}%2023%3A59%20%2B${timeZone}`
  } else {
    currentDate = `${day}-${month}-${year} 23:59 +${timeZone}`
  }
  return currentDate
}

export function getShortFormattedDate(dateString) {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()

  return `${day < 10 ? `0${day}` : day} ${month} ${year}`
}

export const getFormattedDateAndTime = (dateString) =>
  new Date(dateString).toLocaleString()

export const currencyFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

export const indianMobileNumberRegex = /^(?:\+91|91)?[6-9]\d{9}$/

export const isValidMobileNumber = (value) => {
  return indianMobileNumberRegex.test(value)
}

export const getFirstDayoftheCurrentMonth = () => {
  const date = new Date(),
    year = date.getFullYear(),
    month = date.getMonth()
  return new Date(year, month, 1)
}

export const todayDDYYYY = () => {
  const date = new Date()
  const currentDay = String(date.getDate()).padStart(2, '0')
  const currentMonth = String(date.getMonth() + 1).padStart(2, '0')
  const currentYear = date.getFullYear()
  // we will display the date as DD-MM-YYYY
  const currentDate = `${currentDay}-${currentMonth}-${currentYear}`
  return currentDate
}

export const eWayformattedDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getFullYear()
  return `${day}/${month}/${year}`
}

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

export const validateEmail = (e) => {
  return e && e.match(isValidEmail)
}

export const searchProperty = (obj, propName) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      const result = searchProperty(obj[key], propName)
      if (result) {
        return result
      }
    } else if (key === propName) {
      return obj[key]
    }
  }
  return null // Property not found
}

export const addLeadingZero = (number) => {
  return number.length === 1 ? '0' + number : number
}

export function getEndDateOfMonth(date: Date) {
  // Get the year and month from the given date
  const year = date.getFullYear();
  const month = date.getMonth();

  // Create a new date for the last day of the current month
  const lastDayOfMonth = new Date(year, month + 1, 0); // The 0th day of the next month gives us the last day of the current month

  // Set the endDate to the last day of the month
  return lastDayOfMonth
}
