/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/rules-of-hooks */
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showAlertAction } from '../../../store/alerts'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

import {
  fetchMonthlyBillResetAction,
  fetchMonthlyBillsAction,
} from '../../../store/monthlyBills'
import { DeleteMonthlyBill } from './DeleteMonthlyBill'
import {
  currencyFormatter,
  getDisplayDateTime,
  getShortFormattedDate,
} from '../../../utils'

import { CreateMonthlyBill } from './CreateMonthlyBill'
import { EmptyWithAddButton } from '../../../components/Empty/EmptyWithAddButton'
import { Bill } from 'src/services'
import { BillSettings } from '../slips/BillSettings'
import { BarButton } from '../slips/BarButton'
import { CreateBill } from './CreateBill'

export interface MonthlyBillsProps {
  bill: Bill
}

export const MonthlyBills: FC<MonthlyBillsProps> = ({ bill }) => {
  const dispatchAction = useAppDispatch()
  const {
    success,
    data: billsResponse,
    message,
  } = useAppSelector((state) => state.fetchMonthlyBill)

  const [showCreateMonthlyBill, setShowCreateMonthlyBill] = useState(false)
  const [showBillSettings, setShowBillSettings] = useState(false)
  const [showCreateBill, setShowCreateBill] = useState(false)

  useEffect(() => {
    dispatchAction(fetchMonthlyBillResetAction())
  }, [])

  useEffect(() => {
    if (bill) {
      dispatchAction(fetchMonthlyBillsAction(bill.id))
    }
  }, [bill])

  useEffect(() => {
    if (!success && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [message])

  console.log({ bill })
  const columns: GridColDef[] = [
    {
      field: 'intervalName',
      sortable: false,
      headerName: 'Month',
      width: 100,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (value) => (
        <Typography fontWeight={value.row.id < 0 ? 'bold' : '400'}>
          {value.row.intervalName}
        </Typography>
      ),
      sortComparator: (a, b) => (a < 0 ? -1 : a < b ? -1 : a > b ? 1 : 0),
    },
    {
      field: 'invoiceNo',
      headerName: 'Invoice No',
      width: 100,
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'startDate',
      sortable: false,
      headerName: 'Start Date',
      width: 100,
      headerClassName: 'super-app-theme--header',
      flex: 1,

      valueGetter: (params) => {
        return params.row.startDate
          ? getShortFormattedDate(params.row.startDate)
          : ''
      },
    },
    {
      field: 'endDate',
      sortable: false,
      headerName: 'End Date',
      width: 100,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      valueGetter: (params) => {
        return params.row.endDate
          ? getShortFormattedDate(params.row.endDate)
          : ''
      },
    },
    {
      field: 'invoiceTotal',
      headerName: 'Invoice Total',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      width: 100,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (value) => (
        <Typography fontWeight={value.row.id < 0 ? 'bold' : '400'}>
          {currencyFormatter.format(value.row.invoiceTotal)}
        </Typography>
      ),
    },
    {
      field: 'createDate',
      sortable: false,
      headerName: 'Create Date',
      width: 100,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      valueGetter: (params) => {
        return params.row.createDate
          ? getDisplayDateTime(params.row.createDate)
          : ''
      },
    },
    {
      field: 'none',
      headerName: 'Actions  ',
      headerAlign: 'right',
      sortable: false,
      filterable: false,
      width: 100,
      flex: 1,
      align: 'right',
      headerClassName: 'super-app-theme--header',

      renderCell: (value) => {
        const [showDeleteDailog, setShowDeleteDailog] = useState(false)
        return (
          <>
            {value.row.id > 0 && (
              <Stack direction="row" alignItems="right">
                <Tooltip title="View Bill" arrow>
                  <IconButton
                    onClick={() => {
                      window.open(value.row.pdfLink, '_blank')
                    }}
                  >
                    {' '}
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Bill" arrow>
                  <IconButton
                    onClick={() => {
                      setShowDeleteDailog(true)
                    }}
                  >
                    {' '}
                    <DeleteIcon sx={{ color: 'crimson' }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
            {showDeleteDailog && (
              <DeleteMonthlyBill
                bill={value.row}
                onClose={() => setShowDeleteDailog(false)}
              />
            )}
          </>
        )
      },
    },
  ]

  const createBillClickHandler = () => {
    if (bill.isRecurringEnabled) {
      setShowCreateMonthlyBill(true)
    } else {
      setShowCreateBill(true)
    }
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          backgroundColor: '#f0f0f0',
          height: 46,
          alignItems: 'center',
          display: 'flex',
          borderRadius: 0,
          padding: 0,
        }}
      >
        <GridToolbarExport
          startIcon={<PictureAsPdfIcon />}
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{
            hideFooter: true,
          }}
        />
        <BarButton
          id="button_add_recurring_bill"
          title="Create Bill"
          startIcon={<AddCircleIcon />}
          onClick={createBillClickHandler}
        ></BarButton>
        <BarButton
          title="Bill Settings"
          id="id_bill_settings"
          startIcon={<SettingsOutlinedIcon />}
          onClick={() => {
            setShowBillSettings(true)
          }}
        ></BarButton>
        <Box
          sx={{ textAlign: 'right', alignItems: 'flex-end', flex: 1, pr: 1.2 }}
        >
          Ac: {bill.id},Party Name: {bill.customer.name}, Site Address:{' '}
          {bill.siteAddress}
        </Box>
      </GridToolbarContainer>
    )
  }

  return (
    <>
      <Box
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: '#f9f9f9',
            color: 'black',
          },
        }}
      >
        {billsResponse && billsResponse.bills.length === 0 && (
          <EmptyWithAddButton
            onClickAdd={createBillClickHandler}
            buttonTitle="CREATE BILL"
            message="No bill exists for this account. Please click this button to create a monthly bill."
          />
        )}

        {billsResponse && billsResponse.bills.length > 0 && (
          <>
            {billsResponse && billsResponse.warning && (
              <Typography p={1} sx={{ backgroundColor: '#E4A11B70' }}>
                {billsResponse.warning}
              </Typography>
            )}
             {billsResponse && billsResponse.totalMismatchWarning && (
              <Typography p={1} sx={{ color:'white', backgroundColor: billsResponse.messageColor }}>
                {billsResponse.totalMismatchWarning}
              </Typography>
            )}
            <DataGrid
              rows={billsResponse.bills}
              localeText={{ toolbarExport: 'Export PDF' }}
              slots={{
                toolbar: CustomToolbar,
              }}
              sx={{
                '@media print': {
                  '& .MuiDataGrid-main': {
                    zoom: '0.75',
                    fontSize: 16,
                  },
                },
              }}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              pageSizeOptions={[20, 50]}
            />
          </>
        )}
      </Box>
      {showCreateMonthlyBill && (
        <CreateMonthlyBill
          onClose={() => setShowCreateMonthlyBill(false)}
          account={bill}
        />
      )}
      {showCreateBill && (
        <CreateBill onClose={() => setShowCreateBill(false)} account={bill} />
      )}

      {showBillSettings && (
        <BillSettings
          onClose={() => setShowBillSettings(false)}
          account={bill}
        />
      )}
    </>
  )
}

export default MonthlyBills
