import { FC, useState, useReducer, useEffect } from 'react'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

import { Challan, ItemEntryDetail, UpdateEntryDateRequest } from '../../../services'

// Components
import { AppDialog, ChallanSection } from '../../../components'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  refreshFetchSlipsAction,
  updateEntryDateAction,
  resetEntryDateAction,
} from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'
import { getFormattedDate, replace12AmWithCurrentTime } from '../../../utils'
import CustomNumberField from '../Users/profile/CustomNumberField'

export interface EditEntryDateProps {
  onClose?: () => void
  onSubmitSuccess?: () => void
  item: ItemEntryDetail
  accountId: number
}

export const EditEntryDate: FC<EditEntryDateProps> = ({
  onClose,
  onSubmitSuccess,
  item,
  accountId
}) => {
  const dispatchAction = useAppDispatch()
  const {
    success: updateEntryDateSuccess,
    error: updateEntryDateError,
    message,
  } = useAppSelector((state) => state.updateEntryDate)

  const [date, setDate] = useState<Date | null>(
    () => new Date(item?.titleDate != null ? item?.titleDate : Date())
  )
  const [isClose] = useState<boolean>(false)
  const [request, setRequest] = useState<UpdateEntryDateRequest | null>(null)
  const [vehicleNo, setVehicleNo] = useState<string | null>(item?.vehicleNo)
  const [ewayBillNo, setEwayBillNo] = useState<number | null>(item?.ewayBillNo)
  const [selectedChallanNo, setSelectedChallanNo] = useState<string | null>(null)
  const { data: userData } = useAppSelector((state) => state.userData)
  const [prefix, setPrefix] = useState<string | null>(null)
  // New fields for DL, driverName, driverMobile
  const [dl, setDL] = useState<string | null>(item?.DL || '')
  const [driverName, setDriverName] = useState<string | null>(item?.driverName || '')
  const [driverMobile, setDriverMobile] = useState<string | null>(item?.driverMobile || '')

  useEffect(() => {
    if(userData.config) {
     if(item?.challanType === 'OUT') {
       setPrefix(userData.config.outwardChallanPrefix)
     } else if(item?.challanType === 'IN') {
       setPrefix(userData.config.inwardChallanPrefix)
     }
    }
   }, [userData])

  useEffect(() => {
    if (updateEntryDateSuccess) {
      dispatchAction(
        showAlertAction({
          message: 'Updated Successfully',
          severity: 'success',
        })
      )
      onSubmitSuccess() // To close the popup
      dispatchAction(refreshFetchSlipsAction())
      dispatchAction(resetEntryDateAction())
    } else if (updateEntryDateError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      onSubmitSuccess() // To close the popup
      dispatchAction(resetEntryDateAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntryDateSuccess, message, updateEntryDateError])

  const dateChangeHandler = (value) => {
    const newDate = replace12AmWithCurrentTime(value)
    setDate(newDate)

    setRequest({
      ...request,
      entryDate: getFormattedDate(newDate),
    })
  }

  useEffect(() => {
    const { itemEntries } = item
    const entries = itemEntries.map((entry) => {
      return { id: entry.id }
    })
    setRequest({
      entryDate: getFormattedDate(new Date(item?.titleDate)),
      entries: entries,
      vehicleNo: item?.vehicleNo,
      ewayBillNo: item?.ewayBillNo,
      challanId: item?.challanId,
      challanNo: selectedChallanNo,
      DL: dl,  // Set the DL field in the request
      driverName: driverName,  // Set the driver name field in the request
      driverMobile: driverMobile,  // Set the driver mobile field in the request
    })
  }, [dl, driverName, driverMobile])

  useEffect(() => {
    setSelectedChallanNo(item.chaalanNumber)
  }, [item])

  const submitHandle = () => {
    const checkRequest = request.entryDate
    if (checkRequest) {
      dispatchAction(updateEntryDateAction(request))
    }
  }

  const onChangeHandler = (e) => {
    const { value } = e.target
    setVehicleNo(value)
    setRequest({
      ...request,
      vehicleNo: value,
    })
  }

  const onEwayChange = (e) => {
    const { value } = e.target
    setEwayBillNo(value)
    setRequest({
      ...request,
      ewayBillNo: value,
    })
  }

  const onChallanChange = (e) => {
    const { value } = e.target
    setSelectedChallanNo(value)
    setRequest({
      ...request,
      challanNo: value,
    })
  }

  const onDLChange = (e) => {
    const { value } = e.target
    setDL(value)
    setRequest({
      ...request,
      DL: value,
    })
  }

  const onDriverNameChange = (e) => {
    const { value } = e.target
    setDriverName(value)
    setRequest({
      ...request,
      driverName: value,
    })
  }

  const onDriverMobileChange = (e) => {
    const { value } = e.target
    setDriverMobile(value)
    setRequest({
      ...request,
      driverMobile: value,
    })
  }

  return (
    <AppDialog
      id="edit-entry-date"
      title={'Edit Challan'}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
      fixHeight={false}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} mr={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid item={true} xs={12} mt={1.5}>
              <CustomNumberField
                maxLength={14}
                name="challanNo"
                value={selectedChallanNo}
                label="Challan No"
                fullWidth
                onChange={onChallanChange}
              />
                {prefix && (
              <Typography color={'green'} sx={{fontSize:11, ml:1, p:0}}> Full challan no: {prefix}{selectedChallanNo} </Typography>
            )}
            </Grid>

            <Grid item={true} xs={12} mt={1.5}>
              <DateTimePicker
                label="Select Date"
                value={date}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    variant: 'outlined',
                  },
                }}
                ampmInClock={false}
                onChange={dateChangeHandler}
                format="dd/MM/yyyy hh:mm a"
              />
            </Grid>

            <Grid item={true} xs={12} mt={1.5}>
              <TextField
                size="small"
                name="vehicleNo"
                fullWidth
                label="Vehicle No"
                value={vehicleNo}
                onChange={onChangeHandler}
                inputProps={{ style: { textTransform: 'uppercase' } }}  // CSS to display text in uppercase

              />
            </Grid>

            <Grid item={true} xs={12} mt={1.5}>
              <CustomNumberField
                maxLength={14}
                name="ewayBillNo"
                value={ewayBillNo}
                label="e-Way Bill No"
                fullWidth
                onChange={onEwayChange}
              />
            </Grid>

            {/* New Fields for DL, Driver Name, Driver Mobile */}
            <Grid item={true} xs={12} mt={1.5}>
              <TextField
                size="small"
                name="DL"
                fullWidth
                label="Driver License"
                value={dl}
                inputProps={{ style: { textTransform: 'uppercase' } }}  // CSS to display text in uppercase
                onChange={onDLChange}
              />
            </Grid>

            <Grid item={true} xs={12} mt={1.5}>
              <TextField
                size="small"
                name="driverName"
                fullWidth
                label="Driver Name"
                value={driverName}
                onChange={onDriverNameChange}
              />
            </Grid>

            <Grid item={true} xs={12} mt={1.5}>
              <TextField
                size="small"
                name="driverMobile"
                fullWidth
                label="Driver Mobile"
                value={driverMobile}
                onChange={onDriverMobileChange}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </AppDialog>
  )
}