import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material'
import { FC, useState, useReducer, useEffect } from 'react'
import { Customer } from '../../../services'
import { AppDialog } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  addCustomerAction,
  addCustomerResetAction,
  fetchCustomersAction,
} from '../../../store/customers'
import { showAlertAction } from '../../../store/alerts'
import {
  isValidMobileNumber,
  indianMobileNumberRegex,
  isValidGST,
  setDefaultGSTType,
  getDefaultGstType,
} from '../../../utils'
import SelectStateItem, { StateOption, getState } from './SelectStateItem'
import { fetchGSTINAction } from '../../../store/gstr'
import GSTINDetailsItemV2 from '../../../../src/components/GSTINDetailsItemV2'

type AddCustomerFields = 'name' | 'mobile' | 'address' | 'gstin' | 'pan'
type AddCustomerError = Record<AddCustomerFields, Record<string, string>>
const addCustomerErrors: AddCustomerError = {
  name: {
    empty: '',
  },
  mobile: {
    empty: '',
  },
  address: {
    empty: '',
  },
  gstin: {
    empty: '',
  },
  pan: {
    empty: '',
  },
}

type InputBox = {
  value: string
  error: boolean
  helperText: string
}

type AddCustomerFieldsState = Record<AddCustomerFields, InputBox>

const defaultObj: InputBox = {
  value: '',
  error: false,
  helperText: '',
}

const initialAddCustomerState: AddCustomerFieldsState = {
  name: { ...defaultObj },
  mobile: { ...defaultObj },
  address: { ...defaultObj },
  gstin: { ...defaultObj },
  pan: { ...defaultObj },
}

type Action = {
  type: 'SET' | 'ERROR'
  payload: {
    name: AddCustomerFields
    value: string
  }
}
const addCustomerReducer = (state, action) => {
  const {
    type,
    payload: { name, value },
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: { ...state[name], error: false, value: value, helperText: '' },
      }
    case 'ERROR':
      const nameObj = addCustomerErrors[name]
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: nameObj ? nameObj['empty'] : '',
        },
      }
    default:
      return state
  }
}

export interface AddCustomerProps {
  children?: React.ReactNode
  customer?: Customer
  onClose?: (customer?: Customer) => void
}
interface GSTRegType {
  label: string
  index: number
  value: string
}

const AddCustomer: FC<AddCustomerProps> = ({ onClose, children, customer }) => {
  const [stateOption, setStateOption] = useState<StateOption | null>()
  const gstTypes = [
    { label: 'Registered Business - Regular', index: 0, value: 'REGULAR' },
    {
      label: 'Registered Business - Composition',
      index: 1,
      value: 'COMPOSITION',
    },
    { label: 'UIN Holder', index: 2, value: 'UIN' },
    { label: 'Unregistered Business', index: 3, value: 'URG_BUSINESS' },
    { label: 'Unregistered Consumer', index: 4, value: 'URG_CONSUMER' },
    { label: 'Overseas', index: 5, value: 'OVERSEAS' },
    { label: 'Special Economic Zone', index: 6, value: 'SEZ' },
    {
      label: 'Special Economic Zone Developer',
      index: 7,
      value: 'SEZ_DEVELOPER',
    },
    { label: 'Deemed Export', index: 8, value: 'DEEMED_EXPORT' },
    { label: 'Tax Deductor', index: 9, value: 'TAX_DEDUCTED' },
  ]
  const [gstRegType, setGstRegType] = useState<GSTRegType>(gstTypes[0])
  const {
    data,
    success: addCustomerSuccess,
    error: addCustomerError,
    message,
  } = useAppSelector((state) => state.addCustomer)

  const dispatchAction = useAppDispatch()
  const [addCustomerData, dispatch] = useReducer(
    addCustomerReducer,
    initialAddCustomerState
  )

  const { name, mobile, address, gstin, pan } = addCustomerData
  const [isClose, setClose] = useState<boolean>(false)
  const [stateError, setStateError] = useState<boolean>(false)
  const [gstinError, setGstinError] = useState<boolean>(false)

  useEffect(() => {
    const index = Number(getDefaultGstType())
    if(index) {
      setGstRegType(gstTypes[index])
    }
  }, [])
  

  const onChangeHandler = (e) => {
    const {
      target: { name, value },
    } = e
    if (value.length > 0) {
      dispatch({ type: 'SET', payload: { name, value } })
    } else {
      dispatch({ type: 'ERROR', payload: { name, value } })
    }
    if (name === 'gstin' && value.length === 15) {
      dispatchAction(fetchGSTINAction(value))
    }
  }

  function getRegType(regType?: string) {
    let regtType = gstTypes[0]
    gstTypes.map((type) => {
      if (type.value === regType) {
        regtType = type
      }
    })
    return regtType
  }

  const submitHandle = () => {
    let error = false
    if (!name?.value) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'name' } })
    }
    if (!mobile?.value) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'mobile' } })
    }
    if (!isValidMobileNumber(mobile.value)) {
      error = true
      dispatch({
        type: 'ERROR',
        payload: { name: 'mobile', value: mobile.value },
      })
    }
    if (!gstRegType) {
      error = true
    }
    const gstinVisible = !(
      gstRegType?.value === 'URG_BUSINESS' ||
      gstRegType?.value === 'URG_CONSUMER' ||
      gstRegType?.value === 'OVERSEAS'
    )
    if (gstinVisible && (!gstin?.value || !isValidGST(gstin?.value))) {
      error = true
      setGstinError(true)
      dispatch({
        type: 'ERROR',
        payload: { name: 'gstin' },
      })
    }

    if (!address?.value) {
      error = true
      dispatch({ type: 'ERROR', payload: { name: 'address' } })
    }
    if (gstinVisible && !stateOption) {
      error = true
      setStateError(true)
      dispatch({ type: 'ERROR', payload: { name: 'state' } })
    }

    if (!error) {
      dispatchAction(
        addCustomerAction({
          id: customer?.id,
          name: name.value,
          mobile: mobile.value,
          address: address.value,
          gstin: gstinVisible ? gstin.value : '',
          stateCode: stateOption?.code,
          state: stateOption?.label,
          pan: pan.value,
          registrationType: gstRegType.value,
        })
      )
    }
  }

  useEffect(() => {
    if (customer) {
      if (!customer.registrationType && !customer.gstin) {
        setGstRegType(gstTypes[3])
      } else {
        setGstRegType(getRegType(customer.registrationType))
      }
      dispatch({
        type: 'SET',
        payload: { name: 'mobile', value: customer.mobile },
      })
      dispatch({ type: 'SET', payload: { name: 'name', value: customer.name } })
      dispatch({
        type: 'SET',
        payload: { name: 'address', value: customer.address },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'gstin', value: customer.gstin },
      })

      dispatch({
        type: 'SET',
        payload: { name: 'pan', value: customer.pan },
      })

      if (customer.state && customer.stateCode) {
        setStateOption({ code: customer.stateCode, label: customer.state })
      }
    }
  }, [customer, dispatch])

  useEffect(() => {
    // Refresh the customer list
    //dispatchAction(fetchCustomersResetAction())
    if (addCustomerSuccess) {
      dispatchAction(fetchCustomersAction())
      dispatchAction(addCustomerResetAction())
      dispatchAction(
        showAlertAction({ message: 'Done successfully', severity: 'success' })
      )
      onClose(data)
    } else if (message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      dispatchAction(addCustomerResetAction())
    }
  }, [addCustomerSuccess, message])

  const onSelectChange = (e: any, newValue: GSTRegType) => {
    setDefaultGSTType(newValue.index+"")
    setGstRegType(newValue)
  }

  return (
    <AppDialog
      id="add-customer"
      title={!customer ? 'Add Customer' : 'Edit Customer'}
      submitButtonText={!customer ? 'ADD CUSTOMER' : 'EDIT CUSTOMER'}
      onClose={onClose}
      close={isClose}
      onSubmit={submitHandle}
      maxWidth="sm"
    >
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container>
          <Grid item={true} xs={11.6}>
            <Autocomplete
              size="small"
              disablePortal
              id="gstin_type_options"
              value={gstRegType}
              options={gstTypes}
              onChange={onSelectChange}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="Select GST Registration Type"
                  required
                  error={!gstRegType}
                  sx={{ minWidth: '100%', margin: 0 }}
                />
              )}
              renderOption={(props, option) => (
                <Grid component={'li'} container {...props}>
                  <Grid
                    id={option.label}
                    data-cy={option.label}
                    item
                    xs
                    pl={1}
                    pr={1}
                    pt={0.7}
                    pb={0.7}
                  >
                    {option.label}
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          {!(
            gstRegType?.value === 'URG_BUSINESS' ||
            gstRegType?.value === 'URG_CONSUMER' ||
            gstRegType?.value === 'OVERSEAS'
          ) && (
            <>
            <Grid item xs={12}>
             
              <GSTINDetailsItemV2
                minWidth={545}
                gstinError={gstinError}
                initialGstin={gstin.value}
                required
                onUpdate={(gstin, gstinObj) => {
                  setGstinError(false)
                  dispatch({
                    type: 'SET',
                    payload: { name: 'gstin', value: gstin },
                  })
                  if (gstin && gstin.length > 2 && !stateOption) {
                    setStateOption(getState(gstin.substring(0, 2)))
                  }
                  if (
                    gstin &&
                    gstin.length > 11 &&
                    (!pan.value || pan.value.length == 0)
                  ) {
                    dispatch({
                      type: 'SET',
                      payload: { name: 'pan', value: gstin.substring(2, 12) },
                    })
                  }
                  if (gstinObj && gstinObj.legalName) {
                    dispatch({
                      type: 'SET',
                      payload: { name: 'name', value: gstinObj.legalName },
                    })
                    dispatch({
                      type: 'SET',
                      payload: {
                        name: 'address',
                        value:
                          gstinObj.address1 +
                          ', ' +
                          gstinObj.address2 +
                          ' ' +
                          gstinObj.pinCode,
                      },
                    })
                    setStateOption(getState(gstinObj.stateCode))
                  }
                }}
              />
            </Grid>
              <Grid item xs={12}>
              <TextField
                size="small"
                name="pan"
                value={pan.value}
                label="PAN Number"
                error={pan.value && pan.error}
                helperText={pan.helperText}
                onChange={onChangeHandler}
                sx={{ minWidth: 545 }}
                InputLabelProps={{ shrink: pan.value }}
              ></TextField>
            </Grid>
            <Grid item xs={12} mt={1}>
            <SelectStateItem
              error={stateError}
              minWidth={545}
              selectedStateOption={stateOption}
              onChange={(option) => {
                setStateError(false)
                setStateOption(option)
              }}
            />
          </Grid>
        
            </>
          )}
          <Grid item xs>
            <TextField
              size="small"
              name="name"
              value={name.value}
              label="Name"
              error={name.error}
              required
              helperText={name.helperText}
              onChange={onChangeHandler}
              sx={{ minWidth: 545 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} mt={1}>
            <TextField
              size="small"
              name="address"
              value={address.value}
              label="Billing Address"
              error={address.error}
              required
              helperText={address.helperText}
              onChange={onChangeHandler}
              sx={{ minWidth: 545 }}
            ></TextField>
          </Grid>
         

          <Grid item xs={12} mt={1}>
            <TextField
              size="small"
              name="mobile"
              value={mobile.value}
              label="Mobile"
              type="string"
              error={mobile.error}
              required
              helperText={mobile.helperText}
              onChange={onChangeHandler}
              fullWidth
              inputProps={{ maxLength: 13, pattern: indianMobileNumberRegex }}
              sx={{ minWidth: 545 }}
            ></TextField>
          </Grid>
        </Grid>
      </Box>
    </AppDialog>
  )
}

export default AddCustomer
