import { FC, useRef, useState, Fragment } from 'react'
import {
  Typography,
  List,
  Grid,
  IconButton,
  Popover,
  ListItem,
  Button,
  Tooltip,
  Link,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import {
  Bill,
  Customer,
  ItemEntryDetail,
  viewEwayBill,
} from '../../../services'
import {
  SlipsScreenType,
  Loader,
  ConfirmationAppDialog,
} from '../../../components'
import { showAlertAction } from '../../../store/alerts'
import LogoutTwoTone from '@mui/icons-material/LogoutTwoTone'
import LoginTwoTone from '@mui/icons-material/LoginTwoTone'
import PictureAsPdfTwoTone from '@mui/icons-material/PictureAsPdfTwoTone'
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { EditEntryDate } from './EditEntryDate'
import DeleteIcon from '@mui/icons-material/Delete'
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { currencyFormatter, getShortFormattedDate } from '../../../utils'
import IssueMoreItem from '../CurrentAccounts/IssueMoreItem'
import SubmitItem from '../CurrentAccounts/SubmitItem'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { CancelEwayBill } from './CancelEwayBill'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import UpdateIcon from '@mui/icons-material/Update'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { CreateEwayBill } from './eway/CreateEwayBill'
import { UpdateEwayBillPartB } from './UpdateEwayBillPartB'
import { DeleteChallan } from './DeleteChallan'
import { fetchSlipsListAction } from '../../../store/slips'
import { da } from 'date-fns/locale'
import AddCustomer from '../Customers/AddCustomer'

interface TitleActionBarComponentProps {
  data: ItemEntryDetail
  screenType: SlipsScreenType
  billType: string
  onClickViewPDF: (showRate: boolean, title?: string, challanId?: number) => void
  selectedTab?: string
  accountId?: number
  account?: Bill
}

export const TitleActionBarComponent: FC<TitleActionBarComponentProps> = ({
  data,
  onClickViewPDF,
  screenType,
  billType,
  selectedTab,
  accountId,
  account,
}) => {
  const { bill_id, customer_id } = useParams()
  const ref = useRef<any>(null)
  const ewayRef = useRef<any>(null)

  const [showMore, setShowMore] = useState<boolean>(false)
  const [showEway, setShowEway] = useState<boolean>(false)
  const [viewEWayBillLoading, setViewEWayBillLoading] = useState(false)
  const [deleteChallan, setDeleteChallan] = useState<boolean>(false)
  const [editDate, setEditDate] = useState<boolean>(false)
  const [gotoStoreProfile, setGotoStoreProfile] = useState<boolean>(false)
  const [gotoEditCustomer, setGotoEditCustomer] = useState<boolean>(false)
  const [showCancelEwayBill, setShowCancelEwayBill] = useState<boolean>(false)
  const [showUpdateEwayBillPartB, setShowUpdateEwayBillPartB] =
    useState<boolean>(false)
  const [issueMoreItem, setIssueMoreItem] = useState<boolean>(false)
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  )
  const [submitItem, setSubmitItem] = useState<boolean>(false)
  const [entryType, setEntryType] = useState<string | null>(null)
  const slipDateId = `id_typo_${getShortFormattedDate(data.titleDate)}`
  const dispatchAction = useAppDispatch()
  const navigate = useNavigate()
  const { data: userData } = useAppSelector((state) => state.userData)

  function eWayExists() {
    return data?.ewayBillNo && data?.ewayBillNo > 0
  }

  const handleEWayClick = () => {
    if (
      !userData.store.eWayCredentials?.userName ||
      !userData.store.eWayCredentials?.password
    ) {
      setGotoStoreProfile(true)
      return
    }
    if (account?.isGSTEnabled != true) {
      dispatchAction(
        showAlertAction({
          message:
            'GST invocing is not enabled on this account, Please edit account to enable GST invoicing.',
          severity: 'error',
        })
      )
      return
    }
    if (!account?.customer.gstin || account?.customer.gstin?.length === 0) {
      setGotoEditCustomer(true)
      return
    }
    if (eWayExists()) {
      setShowEway(true) // view/cancel
    } else {
      setEntryType(getEntryType())
    }
  }

  function getEntryType(): string | null {
    if (data.itemEntries.length > 0) {
      const entryType: string = data.itemEntries[0].entryType
      return entryType
    } else {
      return null
    }
  }

  const handleViewEWayClick = (ewayNo: number) => {
    setViewEWayBillLoading(true)
    viewEwayBill(ewayNo).then((response) => {
      setViewEWayBillLoading(false)
      if (response.data.success) {
        window.open(response.data.data, '_blank')
      } else if (response.data.message) {
        dispatchAction(
          showAlertAction({
            message: response.data?.message,
            severity: 'error',
          })
        )
      }
    })
  }

  return (
    <Grid
      id="title_actionbar_component"
      container
      spacing={0}
      direction="row"
      alignItems="center"
      sx={{
        minHeight: '1.8vh',
        pl: 2,
        pr: 2,
        backgroundColor: '#e0e0e0',
        justifyContent: 'space-between',
      }}
    >
      {viewEWayBillLoading && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Loader />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Typography
          id="slip_date"
          variant="body2"
          style={{ fontWeight: 700 }}
          data-cy={`${slipDateId}_title`}
        >
          {screenType === SlipsScreenType.Account &&
            getShortFormattedDate(data.titleDate)}
          {screenType === SlipsScreenType.Date && data.customerMobile && (
            <Tooltip arrow title={data.customerMobile}>
              <Link
                href={`https://web.whatsapp.com/send?phone=${data.customerMobile.replace(
                  '+91',
                  '91'
                )}`}
                data-cy={`${slipDateId}_rojnamcha_title`}
              >
                {data.displayTitle}
              </Link>
            </Tooltip>
          )}
          {screenType === SlipsScreenType.Date &&
            !data.customerMobile &&
            data.displayTitle && (
              <span style={{ paddingLeft: 10 }}>{data.displayTitle}</span>
            )}
          {data.chaalanNumber != null && (
            <span
              style={{ paddingLeft: 10, color: '#aa1111' }}
              data-cy={`${slipDateId}_chalan_no`}
            >
              {' '}
              Challan No: {data.displayCHL}
            </span>
          )}
          {data.rentPerDay != null && data?.rentPerDay > 0 && (
            <>
              <span
                id="rent_per_day"
                style={{ paddingLeft: 10, color: '#118811' }}
                data-cy={`${slipDateId}_rent_per_day`}
              >
                Rent: {currencyFormatter.format(data?.rentPerDay)} per day
              </span>
            </>
          )}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          textAlign: 'end',
          visibility:
            screenType === SlipsScreenType.Account ? 'visible' : 'hidden',
        }}
      >
        <Fragment>
          <Tooltip title="Issue more item" arrow>
            <IconButton
              id="issue_item_button"
              onClick={() => setIssueMoreItem(true)}
            >
              {' '}
              <LogoutTwoTone />
            </IconButton>
          </Tooltip>
          <Tooltip title="Receive item" arrow>
            <IconButton
              id="receive_item_button"
              onClick={() => setSubmitItem(true)}
            >
              {' '}
              <LoginTwoTone />
            </IconButton>
          </Tooltip>
          {selectedTab === 'ALL' && (
            <Tooltip title="Edit Challan" arrow>
              <IconButton
                id="edit_slip_date_button"
                onClick={() => {
                  setEditDate(true)
                }}
              >
                <EditCalendarIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* <Tooltip title="Print" arrow>
            <IconButton onClick={() => {}}>
              <PrintTwoTone />
            </IconButton>
          </Tooltip> */}
          {screenType === SlipsScreenType.Account && (
            <>
              <Tooltip title="Challan PDF" arrow>
                <IconButton
                  id="download_pdf_button"
                  onClick={() => {
                    setShowMore(true)
                  }}
                  ref={ref}
                >
                  <PictureAsPdfTwoTone />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eway Bill" arrow>
                <IconButton
                  ref={ewayRef}
                  onClick={() => {
                    handleEWayClick()
                  }}
                >
                  {' '}
                  <LocalShippingIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Challan" arrow>
                <IconButton
                  ref={ewayRef}
                  onClick={() => {
                    setDeleteChallan(true)
                  }}
                >
                  {' '}
                  <DeleteIcon className={'actionButtonRed'} />
                </IconButton>
              </Tooltip>
              <Popover
                open={showMore}
                anchorEl={ref.current}
                onClose={() => {
                  setShowMore(false)
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <List sx={{ p: 0 }} component="nav">
                  <ListItem>
                    <Button
                      sx={{ fontWeight: 400 }}
                      startIcon={<PictureAsPdfTwoTone />}
                      onClick={() => {
                        setShowMore(false)
                        onClickViewPDF(true, null, data.challanId)
                      }}
                    >
                      PDF with rate
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      sx={{ fontWeight: 400 }}
                      startIcon={<PictureAsPdfTwoTone />}
                      onClick={() => {
                        setShowMore(false)
                        onClickViewPDF(false, null, data.challanId)
                      }}
                    >
                      PDF without rate
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      sx={{ fontWeight: 400 }}
                      startIcon={<PictureAsPdfTwoTone />}
                      onClick={() => {
                        setShowMore(false)
                        onClickViewPDF(null, null, data.challanId)
                      }}
                    >
                      PDF with goods value
                    </Button>
                  </ListItem>
                </List>
              </Popover>

              <Popover
                open={showEway}
                anchorEl={ewayRef.current}
                onClose={() => {
                  setShowEway(false)
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <List sx={{ p: 0 }} component="nav">
                  <ListItem>
                    <Button
                      sx={{ fontWeight: 400 }}
                      startIcon={<PictureAsPdfIcon />}
                      onClick={() => {
                        setShowEway(false)
                        handleViewEWayClick(data.ewayBillNo)
                      }}
                    >
                      {'View E-Way Bill'}
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      sx={{
                        fontWeight: 400,
                        color: 'crimson',
                      }}
                      onClick={() => {
                        setShowEway(false)
                        setShowCancelEwayBill(true)
                      }}
                      startIcon={<DisabledByDefaultIcon />}
                    >
                      {'Cancel E-Way Bill'}
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      sx={{
                        fontWeight: 400,
                      }}
                      onClick={() => {
                        setShowEway(false)
                        setShowUpdateEwayBillPartB(true)
                      }}
                      startIcon={<UpdateIcon />}
                    >
                      {'Update Part B'}
                    </Button>
                  </ListItem>
                </List>
              </Popover>
            </>
          )}
          {gotoStoreProfile && (
            <ConfirmationAppDialog
              id="delete-item"
              title={'Eway credentials not found'}
              onClose={() => {
                setGotoStoreProfile(false)
              }}
              close={false}
              dialogActionsButtons={[
                {
                  text: 'No',
                  color: 'error',
                  variant: 'outlined',
                  onClickHandler: () => {
                    setGotoStoreProfile(false)
                  },
                },
                {
                  text: 'Yes',
                  color: 'success',
                  variant: 'contained',
                  onClickHandler: () => {
                    setGotoStoreProfile(false)
                    navigate('/management/profile/details', {
                      state: { from: location.pathname },
                    })
                  },
                },
              ]}
            >
              You haven't set your eWay username and password yet. Go to the
              store profile to set them?
            </ConfirmationAppDialog>
          )}

          {gotoEditCustomer && (
            <ConfirmationAppDialog
              id="delete-item"
              title={'Edit Customer'}
              onClose={() => {
                setGotoEditCustomer(false)
              }}
              close={false}
              dialogActionsButtons={[
                {
                  text: 'No',
                  color: 'error',
                  variant: 'outlined',
                  onClickHandler: () => {
                    setGotoEditCustomer(false)
                  },
                },
                {
                  text: 'Yes',
                  color: 'success',
                  variant: 'contained',
                  onClickHandler: () => {
                    setGotoEditCustomer(false)
                    account.customer.registrationType = 'REGULAR'
                    setSelectedCustomer(account.customer)
                  },
                },
              ]}
            >
              GSTIN number is missing from customer profile. Edit customer
              profile?
            </ConfirmationAppDialog>
          )}

          {showCancelEwayBill && (
            <CancelEwayBill
              slip={data}
              onClose={() => {
                setShowCancelEwayBill(false)
              }}
              accountId={accountId}
            />
          )}

          {showUpdateEwayBillPartB && (
            <UpdateEwayBillPartB
              entryType={getEntryType()}
              eWayNo={data?.ewayBillNo}
              onClose={() => {
                setShowUpdateEwayBillPartB(false)
              }}
            />
          )}

          {editDate && (
            <EditEntryDate
              item={data}
              accountId={Number(bill_id)}
              onClose={() => {
                setEditDate(false)
              }}
              onSubmitSuccess={() => {
                setEditDate(false)
              }}
            />
          )}

          {issueMoreItem && (
            <IssueMoreItem
              bill_id={+bill_id}
              customer_id={+customer_id}
              onClose={() => setIssueMoreItem(false)}
              selectedDate={data.titleDate}
              billType={billType}
              challanNo={data.chaalanNumber?.toString()}
            />
          )}

          {selectedCustomer && (
            <AddCustomer
              onClose={(customer?: Customer) => {
                setSelectedCustomer(null)
                if (customer && customer.gstin) {
                  account.customer = customer
                  handleEWayClick()
                }
              }}
              customer={selectedCustomer}
            />
          )}

          {entryType && (
            <CreateEwayBill
              accountId={Number(bill_id)}
              slip={data}
              entryType={entryType}
              onClose={(ewayNo) => {
                setEntryType(null)
                if (ewayNo) {
                  handleViewEWayClick(Number(ewayNo))
                }
              }}
            />
          )}

          {submitItem && (
            <SubmitItem
              bill_id={+bill_id}
              customer_id={+customer_id}
              onClose={() => setSubmitItem(false)}
              selectedDate={data.titleDate}
              billType={billType}
              challanNo={data.chaalanNumber?.toString()}
            />
          )}

          {deleteChallan && (
            <DeleteChallan
              onClose={() => setDeleteChallan(false)}
              onSubmitSuccess={() => {
                setDeleteChallan(false)
                dispatchAction(fetchSlipsListAction(bill_id))
              }}
              item={data}
            />
          )}
        </Fragment>
      </Grid>
    </Grid>
  )
}
