import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { FC, useState, useReducer, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { showAlertAction } from '../../../../store/alerts'
import {
  getUserProfileDataAction,
  updateUserProfileActionB,
  updateUserProfileResetAction,
} from '../../../../store/users'
import SelectStateItem, {
  StateOption,
  getState,
} from '../../Customers/SelectStateItem'
import CustomNumberField from './CustomNumberField'
import GSTINDetailsItem from '../../../../../src/components/GSTINDetailsItem'
import { EWayCredentials } from 'src/services'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useLocation, useNavigate } from 'react-router'

type StoreProfileFields =
  | 'name'
  | 'mobile'
  | 'address'
  | 'storeName'
  | 'state'
  | 'gstin'
  | 'pan'
  | 'hsnCode'
  | 'ewayHsnCode'
  | 'place'
  | 'pincode'
  | 'nonGSTName'
  | 'nonGSTAddress'
  | 'eWayUserName'
  | 'eWayPassword'

type StoreProfileError = Record<StoreProfileFields, Record<string, string>>
const storeProfileErros: StoreProfileError = {
  name: {
    empty: '',
  },
  mobile: {
    empty: '',
  },
  storeName: {
    empty: '',
  },
  address: {
    empty: '',
  },
  state: {
    empty: '',
  },
  pan: {
    empty: '',
  },
  gstin: {
    empty: '',
  },
  hsnCode: {
    empty: '',
  },
  ewayHsnCode: {
    empty: '',
  },
  place: {
    empty: '',
  },
  pincode: {
    empty: '',
  },
  nonGSTName: {
    empty: '',
  },
  nonGSTAddress: {
    empty: '',
  },
  eWayUserName: {
    empty: '',
  },
  eWayPassword: {
    empty: '',
  },
}

type InputBox = {
  value: string
  error: boolean
  helperText: string
}

type StoreProfileFormState = Record<StoreProfileFields, InputBox>

const defaultObj: InputBox = {
  value: '',
  error: false,
  helperText: '',
}

const initialState: StoreProfileFormState = {
  name: { ...defaultObj },
  mobile: { ...defaultObj },
  address: { ...defaultObj },
  storeName: { ...defaultObj },
  state: { ...defaultObj },
  gstin: { ...defaultObj },
  pan: { ...defaultObj },
  hsnCode: { ...defaultObj },
  ewayHsnCode: { ...defaultObj },
  place: { ...defaultObj },
  pincode: { ...defaultObj },
  nonGSTName: { ...defaultObj },
  nonGSTAddress: { ...defaultObj },
  eWayUserName: { ...defaultObj },
  eWayPassword: { ...defaultObj },
}

type Action = {
  type: 'SET' | 'ERROR'
  payload: {
    name: StoreProfileFields
    value: string
  }
}
const storeProfileReducer = (state, action) => {
  const {
    type,
    payload: { name, value },
  } = action
  switch (type) {
    case 'SET':
      return {
        ...state,
        [name]: { ...state[name], error: false, value: value, helperText: '' },
      }
    case 'ERROR':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          value: value,
          helperText: storeProfileErros[name]['empty'],
        },
      }
    default:
      return state
  }
}

export interface StoreProfileProps {
  children?: React.ReactNode
  hideSaveButton?: boolean
  nextClicked: boolean
}

const StoreProfilePage: FC<StoreProfileProps> = ({
  children,
  hideSaveButton,
  nextClicked,
}) => {
  const { loading, success, message, data } = useAppSelector(
    (state) => state.userData
  )
  const { success: updateSuccess, message: updateErrorMessage } =
    useAppSelector((state) => state.updateProfile)

  const dispatchAction = useAppDispatch()
  const [profileFormData, dispatch] = useReducer(
    storeProfileReducer,
    initialState
  )
  const [stateOption, setStateOption] = useState<StateOption | null>()
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const location2 = useLocation()

  const {
    name,
    mobile,
    address,
    storeName,
    gstin,
    pan,
    hsnCode,
    ewayHsnCode,
    place,
    pincode,
    nonGSTName,
    nonGSTAddress,
    eWayUserName,
    eWayPassword,
  } = profileFormData

  const onChangeHandler = (e) => {
    const {
      target: { name, value },
    } = e
    console.log('name = ', name)
    if (name == 'eWayUserName' || name == 'eWayPassword') {
      dispatch({ type: 'SET', payload: { name, value } })
      return
    }
    if (value.length > 0) {
      dispatch({ type: 'SET', payload: { name, value } })
    } else {
      dispatch({ type: 'ERROR', payload: { name, value } })
    }
  }

  useEffect(() => {
    if (nextClicked) {
      submitHandle()
    }
  }, [nextClicked])

  const validateEWayCredentials = (eWayUserName, eWayPassword) => {
    const errors = {}
    if (!eWayUserName || !eWayUserName.trim()) {
      errors['eWayUserName'] = eWayUserName
    }
    if (!eWayPassword || !eWayPassword.trim()) {
      errors['eWayPassword'] = eWayPassword
    }
    return errors
  }

  const buildEWayCredentials = (eWayUserName, eWayPassword) => {
    return {
      id: data.store.eWayCredentials?.id,
      userName: eWayUserName,
      password: eWayPassword,
    }
  }

  const validateAndBuildEWayCredentials = () => {
    const errors = validateEWayCredentials(
      eWayUserName?.value,
      eWayPassword?.value
    )
    if (Object.keys(errors).length === 0) {
      return buildEWayCredentials(eWayUserName.value, eWayPassword.value)
    } else {
      Object.entries(errors).forEach(([name, value]) => {
        dispatch({ type: 'ERROR', payload: { name, value } })
      })
      return null
    }
  }

  const submitHandle = () => {
    const eWayCredentialsProvided = eWayUserName?.value || eWayPassword?.value
    let eWayCredentials: EWayCredentials
    if (eWayCredentialsProvided) {
      eWayCredentials = validateAndBuildEWayCredentials()
      if (!eWayCredentials) {
        return
      }
    }
    if (
      name.value?.length &&
      mobile.value?.length &&
      address.value?.length &&
      storeName.value?.length
    ) {
      const request = {
        name: name.value,
        mobile: mobile.value,
        store: {
          name: storeName.value,
          address: address.value,
          nonGSTName: nonGSTName.value,
          nonGSTAddress: nonGSTAddress.value,
          gstin: gstin?.value,
          pan: pan?.value,
          hsnCode: hsnCode?.value,
          ewayHsnCode: ewayHsnCode?.value,
          state: stateOption?.label,
          stateCode: stateOption?.code,
          place: place?.value,
          pincode: pincode?.value ? Number(pincode?.value) : null,
          eWayCredentials: eWayCredentials,
        },
      }
      dispatchAction(updateUserProfileActionB(request))
    } else {
      if (!name.value?.length) {
        dispatch({ type: 'ERROR', payload: { name: 'name' } })
      }
      if (!mobile.value?.length) {
        dispatch({ type: 'ERROR', payload: { name: 'mobile' } })
      }
      if (!storeName.value?.length) {
        dispatch({ type: 'ERROR', payload: { name: 'storeName' } })
      }
      if (!address.value?.length) {
        dispatch({ type: 'ERROR', payload: { name: 'address' } })
      }
    }
  }

  useEffect(() => {
    if (success) {
      dispatch({ type: 'SET', payload: { name: 'name', value: data.name } })
      dispatch({
        type: 'SET',
        payload: { name: 'address', value: data.store.address },
      })
      dispatch({ type: 'SET', payload: { name: 'mobile', value: data.mobile } })
      dispatch({
        type: 'SET',
        payload: { name: 'storeName', value: data.store.name },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'state', value: data.store.state },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'gstin', value: data.store.gstin },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'hsnCode', value: data.store.hsnCode },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'ewayHsnCode', value: data.store.ewayHsnCode },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'place', value: data.store.place },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'pincode', value: data.store.pincode },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'nonGSTName', value: data.store.nonGSTName },
      })
      dispatch({
        type: 'SET',
        payload: { name: 'nonGSTAddress', value: data.store.nonGSTAddress },
      })
      dispatch({ type: 'SET', payload: { name: 'pan', value: data.store.pan } })
      if (data.store?.state && data.store?.stateCode) {
        setStateOption({ code: data.store.stateCode, label: data.store.state })
      }
      if (data.store?.eWayCredentials) {
        const eWayCredentials = data.store?.eWayCredentials
        dispatch({
          type: 'SET',
          payload: {
            name: 'eWayUserName',
            value: eWayCredentials.userName,
          },
        })
        dispatch({
          type: 'SET',
          payload: {
            name: 'eWayPassword',
            value: eWayCredentials.password,
          },
        })
      }
    }
  }, [success])

  useEffect(() => {
    if (updateSuccess) {
      dispatchAction(getUserProfileDataAction())
      dispatchAction(
        showAlertAction({
          message: 'Profile Updated Successfully',
          severity: 'success',
        })
      )
      if (location2.state?.from) {
        navigate(location2.state.from)
      }
      dispatchAction(updateUserProfileResetAction())
    } else if (updateErrorMessage) {
      dispatchAction(
        showAlertAction({ message: updateErrorMessage, severity: 'error' })
      )
      dispatchAction(updateUserProfileResetAction())
    }
  }, [updateSuccess, updateErrorMessage])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const gridPadding = 1.4
  return (
    <Box component="form" p={4} noValidate autoComplete="off">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" m={1}>
            Business Owner:
          </Typography>
        </Grid>

        <Grid item xs={6} p={gridPadding}>
          <TextField
            size="small"
            name="name"
            value={name.value}
            label="Name"
            error={name.error}
            helperText={name.helperText}
            onChange={onChangeHandler}
            required
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={6} p={gridPadding}>
          <TextField
            size="small"
            disabled
            name="mobile"
            value={mobile.value}
            label="Mobile"
            error={mobile.error}
            required
            helperText={mobile.helperText}
            onChange={onChangeHandler}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" m={1}>
            Other Details(Optional):
          </Typography>
        </Grid>

        <Grid item xs={6} p={gridPadding}>
          <GSTINDetailsItem
            initialGstin={gstin.value ?? ''}
            initialgstinError={gstin.error}
            onUpdate={(gstin, gstinObj) => {
              dispatch({
                type: 'SET',
                payload: { name: 'gstin', value: gstin },
              })
              if (gstinObj && gstinObj.legalName) {
                dispatch({
                  type: 'SET',
                  payload: { name: 'pincode', value: gstinObj.pinCode },
                })
                dispatch({
                  type: 'SET',
                  payload: { name: 'storeName', value: gstinObj.legalName },
                })
                dispatch({
                  type: 'SET',
                  payload: {
                    name: 'address',
                    value:
                      gstinObj.address1 +
                      ', ' +
                      gstinObj.address2 +
                      ' ' +
                      gstinObj.pinCode,
                  },
                })
                setStateOption(getState(gstinObj.stateCode))
              }
            }}
          />
        </Grid>

        <Grid item xs={6} p={gridPadding}>
          <TextField
            size="small"
            name="pan"
            value={pan.value}
            label="PAN"
            error={pan.error}
            helperText={pan.helperText}
            onChange={onChangeHandler}
            fullWidth
          ></TextField>
        </Grid>
        {/* <Grid item xs={6} p={gridPadding}>
          <TextField
                    size='small'
            name="nonGSTName"
            value={nonGSTName.value}
            label="Non GST Name"
            error={nonGSTName.error}
            helperText={nonGSTName.helperText}
            onChange={onChangeHandler}
            fullWidth
          ></TextField>
        </Grid>

        <Grid item xs={6} p={gridPadding}>
          <TextField
                    size='small'
            name="nonGSTAddress"
            value={nonGSTAddress.value}
            label="Non GST Address"
            error={nonGSTAddress.error}
            helperText={nonGSTAddress.helperText}
            onChange={onChangeHandler}
            fullWidth
          ></TextField>
        </Grid> */}

        <Grid item xs={12}>
          <Typography variant="h5" m={1} mt={4}>
            EWay Credentials:
          </Typography>
        </Grid>
        <Grid item xs={6} p={gridPadding}>
          <TextField
            size="small"
            name="eWayUserName"
            value={eWayUserName.value}
            label="User Name"
            error={eWayUserName.error}
            helperText={eWayUserName.helperText}
            onChange={onChangeHandler}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={6} p={gridPadding}>
          <TextField
            size="small"
            name="eWayPassword"
            value={eWayPassword.value}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            error={eWayPassword.error}
            helperText={eWayPassword.helperText}
            onChange={onChangeHandler}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" m={1} mt={4}>
            Store Details:
          </Typography>
        </Grid>
        <Grid item xs={6} p={gridPadding}>
          <TextField
            size="small"
            name="storeName"
            value={storeName.value}
            label="Business Name"
            error={storeName.error}
            required
            helperText={storeName.helperText}
            onChange={onChangeHandler}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={3} p={gridPadding}>
          <CustomNumberField
            name="hsnCode"
            value={hsnCode.value}
            label="SAC Code(Optional)"
            error={hsnCode.error}
            helperText={hsnCode.helperText}
            onChange={onChangeHandler}
            maxLength={6}
            fullWidth
          ></CustomNumberField>
        </Grid>
        <Grid item xs={3} p={gridPadding}>
          <CustomNumberField
            name="ewayHsnCode"
            value={ewayHsnCode.value}
            label="HSN Code(for eway bills)"
            error={ewayHsnCode.error}
            helperText={ewayHsnCode.helperText}
            onChange={onChangeHandler}
            maxLength={6}
            fullWidth
          ></CustomNumberField>
        </Grid>
        <Grid item xs={6} p={gridPadding}>
          <TextField
            size="small"
            multiline
            rows={5}
            name="address"
            value={address.value}
            label="Store Address"
            error={address.error}
            required
            helperText={address.helperText}
            onChange={onChangeHandler}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={6} p={gridPadding}>
          {' '}
          <SelectStateItem
            selectedStateOption={stateOption}
            onChange={(option) => {
              setStateOption(option)
            }}
          />
          <TextField
            size="small"
            name="place"
            value={place.value}
            label="Place"
            error={place.error}
            sx={{ mt: 3 }}
            onChange={onChangeHandler}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} pl={gridPadding} pr={gridPadding}>
          <CustomNumberField
            size="small"
            maxLength={6}
            name="pincode"
            value={pincode.value}
            label="Pincode"
            error={pincode.error}
            sx={{ mt: 3 }}
            onChange={onChangeHandler}
            fullWidth
          ></CustomNumberField>
        </Grid>

        <Grid item xs={12} mt={4} textAlign={'right'} pr={1.8} mb={1}>
          {!hideSaveButton && (
            <Button
              variant="contained"
              sx={{ backgroundColor: 'green' }}
              onClick={submitHandle}
            >
              {' '}
              SAVE PROFILE
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
export default StoreProfilePage
