import { AxiosResponse } from "axios";
import { http } from "../../../utils";

export function fetchSitesStockPdf(
) {

    let url = `/charts/pvt/sites_stock_summary_pdf`
    return http
        .get(
            url
        )
        .then((response: AxiosResponse) => response.data)
}