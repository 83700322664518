import { FC, Fragment, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import SitesStockSummaryHeader from './PageHeader';
import PageContainerWrapper from '../../../components/PageContainerWrapper';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { tableStyles } from '../Reports';
import { getSitesStockSummaryDataAction, getSitesStockSummaryResetAction } from './actions';
import { showAlertAction } from '../../../store/alerts';


export interface SitesStockSummaryProps {}

export const SitesStockSummary: FC<SitesStockSummaryProps> = ({}) => {
  const classes = tableStyles();
  const {message, success, data: siteReportRows } = useAppSelector(
    (state) => state.sitesStockSummaryState
  );
  const dispatchAction = useAppDispatch();

  useEffect(() => {
      dispatchAction(getSitesStockSummaryResetAction());
      dispatchAction(getSitesStockSummaryDataAction());
  }, [ ]);

  useEffect(() => {
    if(!success && message) {
      dispatchAction(
        showAlertAction({
          message: message,
          severity: 'error',
        })
      )
    }
}, [siteReportRows, message, success]);

  let count = 0;
  return (
    <>
      <Helmet>
        <title>Billing Report</title>
      </Helmet>
      <PageTitleWrapper>
        <SitesStockSummaryHeader/>
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Table aria-label="billing report">
          <TableHead>
            <TableRow>
              <TableCell width={20}>S.N.</TableCell> {/* Serial Number column */}
              <TableCell className={classes.tableCell}>Party Name</TableCell>
              <TableCell className={classes.tableCell}>Site</TableCell>
              <TableCell className={classes.tableCell}>Site ID</TableCell>
              <TableCell className={classes.tableCell}>Item</TableCell>
              <TableCell className={classes.tableCell}>Issued</TableCell>
              <TableCell className={classes.tableCell}>Received</TableCell>
              <TableCell className={classes.tableCell}>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteReportRows &&
              siteReportRows.map((bill, index) => {
                const isNotSum = !(bill.party === "SUM")
                return (
                  <Fragment key={index}>
                    <TableRow>
                      <TableCell >{ isNotSum? ++count : ''}</TableCell> 
                      <TableCell colSpan={isNotSum ? 0 : 4} className={classes.tableCell}><Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.party}</Typography></TableCell>
                      {isNotSum && (<>
                        <TableCell className={classes.tableCell}>{bill.siteAddress}</TableCell>
                        <TableCell className={classes.tableCell}>{isNotSum ? bill.siteNo : ''}</TableCell>
                        <TableCell className={classes.tableCell}>{isNotSum ? bill.itemName : ''}</TableCell>
                      </>)}
                      <TableCell className={classes.tableCell}><Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.issuedQuantity}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.receivedQuantity}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant={isNotSum ? 'body1' : 'h5'}>{bill.balanceQuantity}</Typography></TableCell>
                      </TableRow>
                    
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </PageContainerWrapper>
    </>
  );
};

export default SitesStockSummary;
