import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { RouteObject } from 'react-router'
import SidebarLayout from './layouts/SidebarLayout'
import PrivateRoute from './layouts/PrivateRoute'
import OfflineRoute from './layouts/Offline'
import SuspenseLoader from './components/SuspenseLoader'
import ViewSlips from './content/applications/slips/ViewSlips'
import MyStaff from './content/applications/Staff'
import QuotationItem from './content/applications/CurrentAccounts/Quotation'
import OnboardingScreen from './content/applications/onboarding'
import HeaderLayout from './layouts/HeaderLayout'
import BaseLayout from './layouts/BaseLayout'
import WebsiteLayout from './layouts/WebsiteLayout'
import StartTesting from './content/applications/UITest'
import PendingBills from './content/applications/PendingBills'
import TnCView from './content/applications/TnC'
import BillingReport from './content/applications/Reports'
import SitesRentReport from './content/applications/SiteRentReport'
import SitesMonthlyRentReport from './content/applications/SiteMonthlyRentReport'
import AllSitesLedger from './content/applications/BillingSummary'
import Ledger from './content/applications/Ledger'
import LedgerSummary from './content/applications/LedgerSummary'
import SitesStockSummary from './content/applications/StockSummary'

const Loader = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )
}

const CurrentAccounts = Loader(
  lazy(() => import('./content/applications/CurrentAccounts'))
)

const AllChallans = Loader(
  lazy(() => import('./content/applications/AllChallans'))
)
const RestoreBackup = Loader(
  lazy(() => import('./content/applications/RestoreBackup'))
)
const AllBills = Loader(lazy(() => import('./content/applications/AllBills')))
const GSTR3BReport = Loader(lazy(() => import('./content/applications/GSTR3B')))
const GSTR1Summary = Loader(
  lazy(() => import('./content/applications/GSTR1Summary'))
)

const Bill = Loader(lazy(() => import('./content/applications/Bill')))

const Customers = Loader(lazy(() => import('./content/applications/Customers')))
const ItemCategory = Loader(
  lazy(() => import('./content/applications/ItemCategory'))
)
const Items = Loader(lazy(() => import('./content/applications/Items')))
const PendingItems = Loader(
  lazy(() => import('./content/applications/PendingItems'))
)

const UserProfile = Loader(
  lazy(() => import('./content/applications/Users/profile'))
)

const UserLogin = Loader(
  lazy(() => import('./content/applications/Users/login'))
)

const ActivityPage = Loader(
  lazy(() => import('./content/applications/Activity'))
)

const TaskComponent = Loader(lazy(() => import('./content/applications/Task')))

const ContactUs = Loader(lazy(() => import('./content/applications/Contact')))

// Website Pages
const PricingPage = Loader(lazy(() => import('./content/pages/Pricing')))
const AboutPage = Loader(lazy(() => import('./content/pages/About')))
const ContactPage = Loader(lazy(() => import('./content/pages/Contact')))
const ProductPage = Loader(lazy(() => import('./content/pages/Product')))
const HomePage = Loader(lazy(() => import('./content/pages/Home')))
const PrivacyPolicyPage = Loader(
  lazy(() => import('./content/pages/PrivacyPolicy'))
)
const ShippingPolicyPage = Loader(
  lazy(() => import('./content/pages/ShippingPolicy'))
)
const TermsConditionsPage = Loader(
  lazy(() => import('./content/pages/TermsConditions'))
)
const CancellationRefundPolicyPage = Loader(
  lazy(() => import('./content/pages/CancellationRefundPolicy'))
)

const UserSettings = Loader(
  lazy(() => import('./content/applications/Users/settings'))
)
const ChangePassword = Loader(
  lazy(() => import('./content/applications/Users/change-password'))
)

const SubscriptionPlan = Loader(
  lazy(() => import('./content/applications/Users/subscription-plan'))
)
const ChangeStoreComponent = Loader(
  lazy(() => import('./content/applications/Users/changeStore'))
)

const Status404 = Loader(lazy(() => import('./content/pages/Status/Status404')))
const Status500 = Loader(lazy(() => import('./content/pages/Status/Status500')))
const StatusComingSoon = Loader(
  lazy(() => import('./content/pages/Status/ComingSoon'))
)
const StatusMaintenance = Loader(
  lazy(() => import('./content/pages/Status/Maintenance'))
)

const routes = (isLoggedIn): RouteObject[] => [
  {
    path: '',
    element: <WebsiteLayout />,
    children: [
      {
        path: '',
        element: (
          <OfflineRoute>
            <HomePage />
          </OfflineRoute>
        ),
      },
      {
        path: 'pricing',
        element: (
          <OfflineRoute>
            <PricingPage />
          </OfflineRoute>
        ),
      },
      {
        path: 'products',
        element: (
          <OfflineRoute>
            <ProductPage />
          </OfflineRoute>
        ),
      },
      {
        path: 'about',
        element: (
          <OfflineRoute>
            <AboutPage />
          </OfflineRoute>
        ),
      },
      {
        path: 'contact',
        element: (
          <OfflineRoute>
            <ContactPage />
          </OfflineRoute>
        ),
      },
      {
        path: 'privacy-policy',
        element: (
          <OfflineRoute>
            <PrivacyPolicyPage />
          </OfflineRoute>
        ),
      },
      {
        path: 'shipping-policy',
        element: (
          <OfflineRoute>
            <ShippingPolicyPage />
          </OfflineRoute>
        ),
      },
      {
        path: 'refund-and-cancelation',
        element: (
          <OfflineRoute>
            <CancellationRefundPolicyPage />
          </OfflineRoute>
        ),
      },
      {
        path: 'terms-and-conditions',
        element: (
          <OfflineRoute>
            <TermsConditionsPage />
          </OfflineRoute>
        ),
      },
    ],
  },
  {
    path: 'status',
    children: [
      {
        path: '',
        element: <Navigate to="404" replace />,
      },
      {
        path: '404',
        element: <Status404 />,
      },
      {
        path: '500',
        element: <Status500 />,
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />,
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />,
      },
    ],
  },
  {
    path: '*',
    element: <Status404 />,
  },
  {
    path: 'onboarding',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <OnboardingScreen />,
      },
    ],
  },
  {
    path: 'login',
    element: !isLoggedIn ? <UserLogin /> : <Navigate to="/dashboard" replace />,
  },
  {
    path: '',
    element: <HeaderLayout />,
    children: [
      {
        path: 'select-store',
        element: (
          <PrivateRoute>
            <ChangeStoreComponent />
          </PrivateRoute>
        ),
      },
    ],
  },

  {
    path: 'create-bill',
    element: <SidebarLayout />,
    children: [
      {
        path: ':bill_id',
        element: <Bill />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <PrivateRoute>
        <SidebarLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="current-account" replace />,
      },
      {
        path: 'current-account',
        element: (
          <PrivateRoute>
            <CurrentAccounts />
          </PrivateRoute>
        ),
      },
      {
        path: 'activities',
        element: (
          <PrivateRoute>
            <ActivityPage />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: 'management',
    element: (
      <PrivateRoute>
        <SidebarLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="customers" replace />,
      },
      {
        path: 'customers',
        element: (
          <PrivateRoute>
            <Customers />
          </PrivateRoute>
        ),
      },
      {
        path: 'tnc',
        element: (
          <PrivateRoute>
            <TnCView />
          </PrivateRoute>
        ),
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: (
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            ),
          },
          {
            path: 'due_invoices',
            element: (
              <PrivateRoute>
                <PendingBills />
              </PrivateRoute>
            ),
          },
          {
            path: 'settings',
            element: (
              <PrivateRoute>
                <UserSettings />
              </PrivateRoute>
            ),
          },
          {
            path: 'change-password',
            element: (
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            ),
          },
          {
            path: 'subscription-plans',
            element: (
              <PrivateRoute>
                <SubscriptionPlan />
              </PrivateRoute>
            ),
          },
          {
            path: 'change',
            element: (
              <PrivateRoute>
                <ChangeStoreComponent />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'tasks',
        element: (
          <PrivateRoute>
            <TaskComponent />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: 'stock',
    element: (
      <PrivateRoute>
        <SidebarLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivateRoute>
            {' '}
            <Navigate to="item-category" replace />
          </PrivateRoute>
        ),
      },
      {
        path: 'item-category',
        element: (
          <PrivateRoute>
            {' '}
            <ItemCategory />
          </PrivateRoute>
        ),
      },
      {
        path: 'items',
        children: [
          {
            path: '',
            element: (
              <PrivateRoute>
                {' '}
                <Items />
              </PrivateRoute>
            ),
          },
          {
            path: ':category_id',
            element: (
              <PrivateRoute>
                {' '}
                <Items />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'pending-items',
        children: [
          {
            path: ':item_id',
            element: (
              <PrivateRoute>
                {' '}
                <PendingItems />
              </PrivateRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'testing',
    children: [
      {
        path: 'auto',
        children: [
          {
            path: ':token',
            element: <StartTesting />,
          },
        ],
      },
    ],
  },
  {
    path: 'slips',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'view-slips',
        children: [
          {
            path: ':bill_id/:customer_id',
            element: <ViewSlips />,
          },
        ],
      },
      {
        path: 'by-date',
        children: [
          {
            path: ':screen_type',
            element: <ViewSlips />,
          },
        ],
      },
    ],
  },
  {
    path: 'reports',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'all-bills',
        children: [
          {
            path: '',
            element: <AllBills />,
          },
        ],
      },
    ],
  },
  {
    path: 'reports',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'gstr1',
        children: [
          {
            path: '',
            element: <GSTR1Summary />,
          },
        ],
      },
    ],
  },
  {
    path: 'charts',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'billing-chart',
        children: [
          {
            path: '',
            element: <BillingReport />,
          },
        ],
      },
    ],
  },
  {
    path: 'charts',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'site-wise-rent',
        children: [
          {
            path: '',
            element: <SitesRentReport />,
          },
        ],
      },
    ],
  },
  {
    path: 'charts',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'site-monthly-rent',
        children: [
          {
            path: '',
            element: <SitesMonthlyRentReport />,
          },
        ],
      },
    ],
  },
  {
    path: 'charts',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'all-sites-ledger',
        children: [
          {
            path: '',
            element: <AllSitesLedger />,
          },
        ],
      },
    ],
  },
  {
    path: 'charts',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'ledger-summary',
        children: [
          {
            path: '',
            element: <LedgerSummary />,
          },
        ],
      },
    ],
  },
  {
    path: 'charts',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'sites-stock-summary',
        children: [
          {
            path: '',
            element: <SitesStockSummary />,
          },
        ],
      },
    ],
  },
  {
    path: 'reports',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'gstr-3b',
        children: [
          {
            path: '',
            element: <GSTR3BReport />,
          },
        ],
      },
    ],
  },
  {
    path: 'more',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'all-challans',
        children: [
          {
            path: ':challan_type',
            element: <AllChallans />,
          },
        ],
      },
      {
        path: 'restore-backup',
        children: [
          {
            path: '',
            element: <RestoreBackup />,
          },
        ],
      },
    ],
  },
  {
    path: 'quotation',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'view-quotation',
        children: [
          {
            path: ':bill_id',
            element: <QuotationItem />,
          },
        ],
      },
    ],
  },
  {
    path: 'staff',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" replace />,
    children: [
      {
        path: 'my-staff',
        children: [
          {
            path: '',
            element: <MyStaff />,
          },
        ],
      },
    ],
  },
  ,
  {
    path: 'support',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/" replace />,
    children: [
      {
        path: 'contact',
        children: [
          {
            path: '',
            element: <ContactUs />,
          },
        ],
      },
    ],
  },
]

export default routes
